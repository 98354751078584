.p-multiselect {
  max-height: 100px !important;
}

db-multiselect:not(.hide-placeholder-icon) .p-placeholder::before {
  content: " ";
  background-image: url("../../assets/icons/search.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
}

.p-treeselect-items-wrapper {
  max-width: 400px !important;
  max-height: 200px !important;
  overflow: scroll !important;
}

.full-width-multiselect {
  .p-multiselect {
    width: 100%;
  }
}

.multiselect-width-200 {
  .p-multiselect {
    width: 200px;
  }
}

.multiselect-max-width-300 {
  .p-multiselect {
    max-width: 300px;
  }
}

.multiselect-width-250 {
  .p-multiselect {
    width: 250px;
  }
}

.multiselect-width-350 {
  .p-multiselect {
    width: 350px;
  }
}

.no-border {
  /* no-border style aligned with all other dropdowns, inputs etc.*/
  .p-multiselect {
    border-color: transparent;
  }
}

.multiselect-panel-width-250.p-multiselect-panel {
  width: 250px;

  .p-multiselect-items .p-multiselect-item {
    span {
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.multiselect-panel-width-350.p-multiselect-panel {
  width: 350px;

  .p-multiselect-items .p-multiselect-item {
    span {
      max-width: 350px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.multiselect-panel-width-550.p-multiselect-panel {
  width: 550px;

  .p-multiselect-items .p-multiselect-item {
    span {
      max-width: 550px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.db-multiselect-selected-items-container {
  // Analog class to p-multiselect-label for selected values label used for multiselect with custom selectedItems template
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8;
}

/* Multiselect - fix clear icon making the whole multiselect have some additional spacing bellow selected items */
.p-multiselect-label-container .p-icon-wrapper {
  display: flex;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  color: #565656;
  border-radius: 50px;
  margin-right: 4px;
}

.db-multiselect-with-multipurpose-checkbox {
  .p-multiselect-item {
    .db-multipurpose-check-container {
      position: relative;

      .select-check-box,
      .select-minus {
        /* Position checkbox or minus on top of the prime selected checkbox*/
        position: absolute;
        width: 14px;
        height: 14px;
        left: -26px;
        top: 1px;
        background-position: center;
      }

      .select-check-box {
        background-image: url("/assets/icons/checkbox-bg-purple.svg");
      }

      .select-minus {
        background-image: url("/assets/icons/minus-bg-purple.svg");
      }
    }
  }
}

.p-multiselect-trigger-icon {
  height: 14px;
}
